@charset "UTF-8";
@use "reset";
@use "settings" as s;
@use "user_reset";
@use "common";
@use "splide";

@function clampVw($min, $size, $max, $vp: 1920) {
	$min: $min * 0.1rem;
	$max: $max * 0.1rem;
	$num: 100 / $vp * 1vw;
  	$size_vw: $num * $size;
	@return clamp($min, $size_vw, $max);
}

@function vw ($num){
    $viewport: 1680;
    $mathVw: $num / $viewport * 100vw;
    @return round( $mathVw * 1000) / 1000;
}

$color: #1e5199;

.kv{
	position: relative;
	//height: 100%;
	height: 100vh;
	display: flex;
	justify-content: center;
	align-items: center;
	flex-direction: column;
	&__catch{
		font-size: 6.4rem;
		font-size: clampVw(32, 64, 64, 768);
		font-weight: 500;
		line-height: calc(78 / 64);
		color: #fff;
		text-transform: initial;
		text-align: center;
	}
}
.btn{
	&--play{
		margin-top: 25px;
	}
}

.movie{
	position: fixed;
	z-index: -1;
	top: 0;
	right:0;
	left:0;
	bottom:0;
	overflow: hidden;
	video[poster]{
		object-fit: cover;
	}
	&__video{
		position: absolute;
		z-index: -1;
		top: 50%;
		left: 50%;
		transform: translate(-50%, -50%);
		/*縦横幅指定*/
		width: 177.77777778vh; /* 16:9 の幅→16 ÷ 9＝ 177.77% */
		height: 56.25vw; /* 16:9の幅 → 9 ÷ 16 = 56.25% */
		min-height: 100%;
		min-width: 100%;
	}
	&::after{
		position: absolute;
		top: 0;
		left: 0;
		content: '';
		display: block;
		width: 100%;
		height: 100%;
		background: rgba(0,0,0,.45);
	}
}


//contents

.contents{
	background: #fff;
}


//カテゴリー
.cat{
	&__kv{
		height: 707px;
		@include s.mq(w767){
			height: 100vh;
		}
		background-position: center center;
		background-size: cover;
		display: flex;
		justify-content: center;
		align-items: center;
		flex-direction: column;
		color: #fff;
		padding: 0 20px;
		//home
		&--home{
			background-image: url(../img/top/home-kv@2x.jpg);
		}
		//business
		&--business{
			background-image: url(../img/top/business-kv@2x.jpg);
		}
	}
	&__head{
		font-size: 1.8rem;
		font-weight: 700;
		text-align: center;
		&--en{
			font-size: 3rem;
			font-size: clampVw(30, 125, 125, 768);
			font-weight: 300;
			letter-spacing: -.0875em;
			line-height: 1;
			display: block;
			margin-top: 10px;
		}
	}
	&__catch{
		font-size: 1.4rem;
		font-weight: 500;
		margin-top: 6px;
	}
	.btn{
		margin-top: 20px;
	}

	//cat list
	&__slider{
		display: flex;
		justify-content: flex-end;
	}
	&__list{
		width: 100%;
		max-width: 85.4vw;
		padding: 55px 0 103px 0;
		position: relative;
		&-head{
			font-size: 2rem;
			color: #000;
		}
		&-wrap{

		}
	}
	&__track{
		margin-top: 10px;
	}
	&__item{
		width: 386px;
		height: 386px;
		@include s.mq(w767){
			width: 300px;
			height: 300px;
		}
		display: flex;
		justify-content: center;
		align-items: center;
		flex-direction: column;
		position: relative;
		background-repeat: no-repeat;
		background-position: center center;
		background-size: cover;
		.btn{
			position: absolute;
			bottom: 23px;
		}
		//画像 home
		&--home{
			&-solar{
				background-image: url(../img/cat/home-solar@2x.jpg);
			}
			&-storagebattery{
				background-image: url(../img/cat/home-storagebattery@2x.jpg);
			}
			&-v2h{
				background-image: url(../img/cat/home-v2h@2x.jpg);
			}
			&-ecocute{
				background-image: url(../img/cat/home-ecocute@2x.jpg);
			}
			&-electricpower{
				background-image: url(../img/cat/home-electricpower@2x.jpg);
			}
			&-realestate{
				background-image: url(../img/cat/home-realestate@2x.jpg);
			}
			&-afterfit{
				background-image: url(../img/cat/home-afterfit@2x.jpg);
			}
		}
		//画像 business
		&--business{
			&-solar{
				background-image: url(../img/cat/business-solar@2x.jpg);
				.cat__name{
					//font-size: 1.4rem;
					text-align: center;
					line-height: 1.4;
				}
			}
			&-storagebattery{
				background-image: url(../img/cat/business-storagebattery@2x.jpg);
			}
			&-batterycharger{
				background-image: url(../img/cat/business-batterycharger@2x.jpg);
			}
			&-construction{
				background-image: url(../img/cat/business-construction@2x.jpg);
			}
			&-electricpower{
				
				
				background-image: url(../img/cat/business-electricpower@2x.jpg);
			}
			&-realestate{
				background-image: url(../img/cat/business-realestate@2x.jpg);
			}
		}
		
	}
	&__name{
		font-size: 2.8rem;
		font-size: clampVw(22, 28, 28, 768);
		font-weight: 700;
		color: #fff;
	}
	.splide__arrow--prev{
		transform: translateX(-70px);
	}
	//スライダー
	// .splide{
	// 	&__arrows{
	// 		position: absolute;
	// 		width: 100%;
	// 		top: 50%;
	// 		transform: translateY(-50%);
	// 		display: flex;
	// 		justify-content: space-between;
	// 		padding: 0 10px;
	// 	}
	// }
}


//onelove

.onelove{
	background: url(../img/top/onelove-kv@2x.jpg) no-repeat center center;
	background-size: cover;
	height: 753px;
	padding-right: 96px;
	display: flex;
	justify-content: flex-end;
	align-items: center;
	@include s.mq(w767){
		height: 100vh;
		justify-content: center;
		padding: 0 20px;
	}
	&__contents{
		width: 100%;
		max-width: 445px;
		color: #fff;
	}
	&__catch{
		font-size: 2.5rem;
		font-size: clampVw(18, 25, 25, 768);
		font-weight: 700;
		margin-top: 25px;
	}
	&__txt{
		font-size: 1.4rem;
		font-weight: 700;
		margin-top: 17px;
		line-height: calc(24 / 14);
	}
	&__txt + &__txt{
		margin-top: 1.5em;
	}
	.btn{
		margin-top: 20px;
	}
}

//news

.news{
	font-size: 2.8rem;
	padding: 120px 0 100px 0;
	text-align: center;
	&__slider{
		display: flex;
		justify-content: flex-end;
	}
	&__track{
		margin-top: 23px;
	}
	&__list{
		width: 100%;
		max-width: 93.3vw;
		position: relative;
		@include s.mq(w767){
			max-width: 80vw;
		}
	}
	&__item{
		display: flex;
		flex-direction: column-reverse;
		justify-content: flex-end;
		width: 336px;
		// height: 468px;
	}
	&__link{
		display: block;
		//position: relative;
		// width: 336px;
		// height: 468px;
		// display: flex;
		// flex-direction: column;
		// justify-content: flex-end;
		//text-align: left;
		// background: url(../img/top/news-blank.png) no-repeat center center;
		// background-size: cover;
		//color: #fff;
		// @include s.mq(w767){
		// 	width: 260px;
		// 	height: 340px;
		// }
		// &--noeycatch{
		// 	color: #000;
		// }
	}
	&__contents{
		// position: absolute;
		// width: 100%;
		// //color: #fff;
		// padding: 15px 15px 33px 15px;
		margin-top: 10px;
		text-align: left;
	}
	&__cat{
		font-size: 1.2rem;
		font-weight: 500;
		background: #000;
		color: #fff;
		display: inline-block;
		padding: 3px 6px;
		border-radius: 3px;
	}

	// .news__link--noeycatch{
	// 	.news__contents{
	// 		color: #000;
	// 	}
	// }


	&__title{
		font-size: 1.8rem;
		font-weight: 700;
		margin-top: 8px;
		line-height: 1.5;
		@include s.mq(w767){
			font-size: 1.6rem;
		}
		&--link{
			color: #000;
		}
	}
	&__date{
		font-size: 1.2rem;
		margin-top: 8px;
		display: block;
	}
	&__img{
		// top: 0;
		// bottom: 0;
		// width: 100%;
		
		img{
			object-fit: cover;
			aspect-ratio: 1/1;
		}
	}
	.btn{
		margin-top: 47px;
	}
	//スライダー
	.splide{
		&__arrows{
			position: absolute;
			width: 100%;
			top: 50%;
			transform: translateY(-50%);
			display: flex;
			justify-content: space-between;
			padding: 0 10px;
			pointer-events: none;
			@include s.mq(w767){
				justify-content: space-between;
			}
		}
		&__arrow{
			pointer-events: visible;
		}
		.splide__arrow--prev{
			transform: translateX(-70px);
		}
	}	
}

//mission

.mission{
	height: 58.33vw;
	background: #edeeef;
	// display: flex;
	// justify-content: flex-end;
	// align-items: center;
	padding: 0 20px;
	position: relative;
	@include s.mq(w767){
		height: 100%;
		padding: 48px 20px 55px 20px;	
	}
	&__kv{
		width: 36.48vw;
		position: absolute;
		right: 5.65vw;
		top: 4.28vw;
		@include s.mq(w767){
			position: static;
			width: 100%;
		}
	}
	&__header{
		position: absolute;
		top: 17.38vw;
		left: 5.65vw;
		z-index: 1;
		@include s.mq(w767){
			top: 4.5vw;
			left: 10px;
		}
	}
	&__body{
		position: absolute;
		top: 35vw;
		left: 9.7vw;
		@include s.mq(w767){
			position: static;
		}
	}
	&__head{
		font-size: 3.9rem;
		transform: translateX(5px);
		@include s.mq(w767){
			font-size: 2rem;
		}
	}
	.en--head{
		&::after{
			width: 28px;
			@include s.mq(w767){
				width: 16px;
			}
		}
	}
	&__catch{
		//font-size: 10.1rem;
		font-size: 6vw;
		// font-size: clampVw(66, 100, 6vw, 768);
		line-height: calc(109 / 101);
		font-weight: 500;
		text-align: left;
		text-transform: initial;
		@include s.mq(w767){
			font-size: 5rem;
		}
	}
	&__title{
		font-size: 2.2rem;
		font-size: clampVw(20, 22, 22, 768);
		font-weight: 500;
		margin-top: 20px;
		text-align: left;
	}
	&__txt{
		font-size: 1.6rem;
		font-weight: 500;
		line-height: calc(32 / 16);
		margin-top: 16px;
	}
	.pagenav{
		display: block;
	}
	.btn{
		margin-top: 16px;
	}
}
